import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import TextTransition from "react-text-transition"
import landingPic from "../../assets/images/landing/landing.png"
import landingPicMobile from "../../assets/images/landing/landing-mobile.png"

const CREATE_TEXTS = [
  "amazing things",
  "MiniTales",
  "cartoons",
  "toys",
  "custom prints",
]

function AndCreateText() {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => (index + 1) % CREATE_TEXTS.length),
      3000
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <Flex placeContent="center">
      <Text
        color={[
          "secondary.500",
          "secondary.500",
          "secondary.500",
          "secondary.500",
          "white",
        ]}
        size={["sm", "sm", "md"]}
        width="fit-content"
        mb="0.5rem"
      >
        And create
      </Text>
      <Box ml=".25em">
        <TextTransition>
          <Text
            color={[
              "secondary.500",
              "secondary.500",
              "secondary.500",
              "secondary.500",
              "white",
            ]}
            size={["sm", "sm", "md"]}
            width="fit-content"
            mb="0.5rem"
            display="flex"
          >
            {CREATE_TEXTS[index]}
          </Text>
        </TextTransition>
      </Box>
      <Text
        color={[
          "secondary.500",
          "secondary.500",
          "secondary.500",
          "secondary.500",
          "white",
        ]}
        size={["sm", "sm", "md"]}
      >
        !
      </Text>
    </Flex>
  )
}

export default function HomeHeroBlock({ onClick }: { onClick: () => void }) {
  return (
    <Box position="relative" textAlign="center">
      <Image
        src={landingPic}
        display={["none", "none", "none", "block"]}
        w="100%"
        h={["250px", "350px", "auto"]}
        objectFit="cover"
        objectPosition={["70%", "90%", "100%", "100%", "0 100%"]}
        minH={["250px", "350px", "450px", "450px", "650px"]}
        maxH="80vh"
      />
      <Image
        src={landingPicMobile}
        display={["block", "block", "block", "none"]}
        w="100%"
        h={["350px", "450px", "auto"]}
        objectFit="cover"
        objectPosition={"50% 100%"}
        minH={["250px", "350px", "450px", "450px", "650px"]}
        maxH="80vh"
      />
      <Box
        position={["initial", "initial", "initial", "initial", "absolute"]}
        top="50%"
        left="30%"
        transform={[
          "initial",
          "initial",
          "initial",
          "initial",
          "translate(-50%, -50%)",
        ]}
        pb={[0, 0, 0, 0, "5rem"]}
        pt={["2rem", "2rem", "2rem", "2rem", "0"]}
      >
        <Heading
          size={["sm", "sm", "sm", "md"]}
          variant={["solid", "solid", "solid", "solid", "white"]}
          textAlign="center"
        >
          The First Safe <Box display={["block", "block", "block", "none"]} />{" "}
          Chat-GPT for Kids
        </Heading>
        <Text
          color={[
            "secondary.500",
            "secondary.500",
            "secondary.500",
            "secondary.500",
            "white",
          ]}
          textAlign="center"
        >
          Foster your imagination & curiosity
        </Text>
        <AndCreateText />
        <Button
          onClick={onClick}
          variant={["fullSecondary", "fullSecondary", "fullSecondary", "fullSecondary", "white"]}
          w="80%"
          maxW="400px"
          mt="0.5rem"
        >
          Try it now!
        </Button>
      </Box>
    </Box>
  )
}
