import { useNavigate } from "react-router-dom"
import Background from "../../components/Wrapper/Background"
import Header from "./Header"
import HomeHeroBlock from "./HomeHeroBlock"
import DrawingsBlock from "./DrawingsBlock"
import StoriesBlock from "./StoriesBlock"
import ComicsBlock from "./ComicsBlock"
import { useSegment } from "../../../context/SegmentContext"
import Footer from "./Footer"

export default function Home() {
  const navigate = useNavigate()
  const { track } = useSegment()

  return (
    <Background overflowY={"auto"}>
      <Header
        onClick={() => {
          navigate("/sign-up")
          track("Try Our App")
        }}
      />
      <HomeHeroBlock onClick={() => navigate("/chat")} />
      <DrawingsBlock onClick={() => navigate("/sign-up")} />
      <StoriesBlock onClick={() => navigate("/sign-up")} />
      <ComicsBlock onClick={() => navigate("/sign-up")} />
      <Footer onClick={() => navigate("/sign-up")} />
    </Background>
  )
}
