import { Box, Flex, Heading } from "@chakra-ui/react"
import { useUserContext } from "../../../context/userContext"
import Creation from "../../../data/models/Creation"
import UploadActionBlock from "../../components/ActionBlocks/UploadActionBlock"
import CreateNewButton from "../../components/Buttons/CreateNewButton"
import CharacterCard from "../../components/Creations/CharacterCard"
import PendingCreationCard from "../../components/Creations/PendingCreationCard"
import { useNavigate } from "react-router-dom"
import CreationService from "../../../service/CreationService"

function isValidated(creation: Creation) {
  return (
    creation.isValidated === true &&
    (creation.generativeAiResults?.length ?? 0) === 4
  )
}

function isSketchQuest(creation: Creation) {
  return creation.questId != null
}

export default function MyCharacters() {
  const navigate = useNavigate()
  const { userCreations } = useUserContext()

  const characters: Creation[] = userCreations
    .filter((creation) => (creation.selectedImage?.length ?? 0) > 0)
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

  const ongoing = userCreations
    .filter((c) => (c.selectedImage?.length ?? 0) === 0)
    .sort((a, b) => {
      if (
        a.generativeAiResults?.length === 4 &&
        b.generativeAiResults?.length !== 4
      )
        return -1
      if (
        a.generativeAiResults?.length !== 4 &&
        b.generativeAiResults?.length === 4
      )
        return 1
      return b.createdAt.getTime() - a.createdAt.getTime()
    })

  return (
    <Box pt="2em">
      <Flex gap="1rem" mb="1rem">
        <Heading size="sm" as="h2" color="black" textAlign="left">
          My Art
        </Heading>
        <CreateNewButton ml="auto" />
      </Flex>
      {characters.length > 0 || ongoing.length > 0 ? (
        <Flex overflowX={"auto"} gap="1em" px="1em" mx="-1em" pb="1em">
          {ongoing.map((creation) => (
            <PendingCreationCard
              ready={isValidated(creation) ?? false}
              key={creation.id}
              creation={creation}
              onClick={async () => {
                if (isValidated(creation)) {
                  await CreationService.setGiftOpen(creation)
                }
                navigate(`/creations/${creation.id}`)
              }}
            />
          ))}
          {characters.map((creation) => (
            <CharacterCard
              cloudsColor="secondary.500"
              key={creation.id}
              creation={creation}
              onClick={() => {
                if (isSketchQuest(creation))
                  navigate(`/weeklyTheme/${creation.questId}`)
                else navigate(`/creations/${creation.id}`)
              }}
            />
          ))}
        </Flex>
      ) : (
        <Box maxW="500px">
          <UploadActionBlock />
        </Box>
      )}
    </Box>
  )
}
