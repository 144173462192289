import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import transformation1 from "../../assets/images/landing/transformation-1.png"
import transformation2 from "../../assets/images/landing/transformation-2.png"
import transformation3 from "../../assets/images/landing/transformation-3.png"
import transformation4 from "../../assets/images/landing/transformation-4.png"
import transformation5 from "../../assets/images/landing/transformation-5.png"

export default function DrawingsBlock({ onClick }: { onClick: () => void }) {
  return (
    <Flex
      justifyContent="space-between"
      mt={[0, 0, "4rem"]}
      flexDir={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
      textAlign="center"
      overflow="hidden"
      pb={"2rem"}
      rowGap={"2rem"}
      m="auto"
      maxW="1600px"
    >
      <Box textAlign="center" my="auto" flexGrow={1}>
        <Heading size={["sm", "md"]} mb="1.5rem">
          Turn your drawings to <br />
          magical characters
        </Heading>
        <Text size="sm" fontWeight="light" mb="1.5rem">
          & create incredible stories with them
        </Text>
        <Button variant="fullSecondary" onClick={onClick}>Upload a drawing</Button>
      </Box>
      <Flex
        flexDir={["row-reverse", "row-reverse", "row"]}
        alignItems="center"
        mt={["0", "0", "-4rem", "-4rem", "-2rem"]}
        pl={[0, 0, "210px"]}
        pr="48px"
        pt={["80px", "80px", "16px", "16px", 0]}
        pb={[0, 0, 0, 0, "32px"]}
        ml={["-25px", "none"]}
        placeSelf="center"
      >
        <Flex
          flexDir="column"
          transform="rotate(15deg)"
          flexWrap={"wrap-reverse"}
          maxH={["", "", "550px"]}
          maxW="600px"
        >
          <Image
            minW={["180px", "240px"]}
            maxW={["180px", "240px"]}
            src={transformation1}
            mb="2rem"
            mt="-2rem"
          />
          <Image
            minW={["180px", "240px"]}
            maxW={["180px", "240px"]}
            src={transformation2}
          />
          <Image
            minW={["180px", "240px"]}
            maxW={["180px", "240px"]}
            src={transformation5}
            mt={["2rem", "2rem", "5rem"]}
            mr={[0, 0, "-24px"]}
          />
        </Flex>
        <Box
          transform={"rotate(15deg)"}
          mt={["-200px", "-200px", "80px"]}
          mr={["-2rem", "-2rem", 0]}
          ml={[0, 0, "-1rem"]}
          zIndex={[-1, -1, 0]}
        >
          <Image
            minW={["180px", "240px"]}
            maxW={["180px", "240px"]}
            src={transformation3}
            mb="2rem"
            mt="5rem"
          />
          <Image
            minW={["180px", "240px"]}
            maxW={["180px", "240px"]}
            src={transformation4}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
