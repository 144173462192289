import { Flex, Image, Spinner, Text } from "@chakra-ui/react"
import { useState } from "react"
import { useSegment } from "../../../../context/SegmentContext"
import camcam from "../../../assets/images/camcam.png"
import Button3d from "../../../components/Buttons/Button3d"

export type GuessState = "none" | "guessing" | "guessed"

export default function CamcamGuess(props: {
  onGuess: () => void
  guess: GuessState
}) {
  const [guessQuality, setGuessQuality] = useState(null as boolean | null)
  const { track } = useSegment()
  let action: React.ReactNode

  if (props.guess === "guessed") {
    if (guessQuality === null) {
      action = (
        <>
          <Text
            textShadow="0 0 4px white"
            color="#515774"
            size="xs"
            fontWeight="bold"
          >
            Did I guess right?
          </Text>
          <Flex gap="1em" flexWrap="wrap">
            <Button3d
              size="sm"
              onClick={() => {
                setGuessQuality(false)
                track("CamCam was wrong")
              }}
              flexGrow={1}
              width="fit-content"
              variant="ghostPrimary"
              border="solid 1px"
              borderColor="primary.500"
            >
              Not really
            </Button3d>
            <Button3d
              size="sm"
              onClick={() => {
                setGuessQuality(true)
                track("CamCam guess right")
              }}
              width="fit-content"
              flexGrow={1}
              variant="ghostPrimary"
              border="solid 1px"
              borderColor="primary.500"
            >
              Yes, you are right
            </Button3d>
          </Flex>
        </>
      )
    } else {
      action = (
        <Text
          size="md"
          color="#101010"
          fontWeight="bold"
          bg="#ffffff90"
          backdropFilter="blur(4px)"
          mr="auto"
          p="8px 16px"
          borderRadius="8px 8px 8px 0"
        >
          {guessQuality === true ? "I knew it 😎" : "Aww, sorry about that 😢"}
        </Text>
      )
    }
  } else {
    action = (
      <>
        <Text
          size="xs"
          textShadow="0 0 4px white"
          color="#515774"
          fontWeight="bold"
        >
          Camcam can help you
        </Text>
        <Button3d
          bg="white"
          bg3dColor="#515774"
          color="#515774"
          size="sm"
          onClick={() => {
            props.onGuess()
            track("Ask CamCam")
          }}
          variant="ghostPrimary"
          disabled={props.guess === "guessing"}
          mr="auto"
        >
          {props.guess === "guessing" ? (
            <>
              <Spinner mr="0.25em" />
              {" Guessing..."}
            </>
          ) : (
            "Let me make a guess!"
          )}
        </Button3d>
      </>
    )
  }

  return (
    <Flex width="100%" h="160px">
      <Image src={camcam} width="100px" objectFit="contain" py="1em" />
      <Flex flexDir="column" justifyContent="center" pl="0.5rem" flexGrow={1}>
        {action}
      </Flex>
    </Flex>
  )
}
