export default function cleanPrompt(prompt: string): string {
  let parts = prompt.split(", ")
  let words = parts[0].split(" ")

  let ofPos = words.indexOf("of")
  if (ofPos > -1 && ofPos < 3) {
    words = words.slice(ofPos + 1)
    parts[0] = words.join(" ")
  }

  for (let i = 0; i < parts.length; i++) {
    words = parts[i].split(" ")

    if (
      parts[i].includes("drawing") ||
      parts[i].includes("art") ||
      parts[i].includes("painting") ||
      parts[i].includes("sketch") ||
      parts[i].includes("piece of paper")
    )
      parts[i] = ""
    else if (words[0] === "in") parts[i] = ""
  }

  return parts.filter((part) => part.length > 0).join(", ")
}
