import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react"
import {
  RedCloudsBottom,
  RedCloudsBottomCropped,
} from "../../components/SectionSeparators/RedCloudsBottom"
import InstagramIcon from "../../components/Icons/InstagramIcon"
import FacebookIcon from "../../components/Icons/FacebookIcon"
import TwitterIcon from "../../components/Icons/TwitterIcon"
import LinkedinIcon from "../../components/Icons/LinkedinIcon"
import { useNavigate } from "react-router-dom"
import { IS_PROD } from "../../components/Creations/GlobalSettings"

export default function Footer({ onClick }: { onClick: () => void }) {
  const navigate = useNavigate()
  return (
    <Box w="100%" maxW="100%">
      <RedCloudsBottom
        color="secondary.500"
        width="100%"
        height="0%"
        display={["none", "none", "block"]}
      />
      <RedCloudsBottomCropped
        color="secondary.500"
        width="100%"
        height="0%"
        display={["block", "block", "none"]}
      />
      <Flex
        bg="secondary.500"
        py="50px"
        justifyContent="space-evenly"
        alignItems="center"
        flexDir={["column", "column", "row"]}
        gap="1rem"
      >
        <Box>
          <Heading
            color="white"
            size="sm"
            textAlign={["center", "center", "left"]}
          >
            Ministudio
          </Heading>
          <Text
            size="xs"
            color="white"
            fontWeight="light"
            my="1rem"
            display={["none", "none", "block"]}
          >
            Our goal is to enable kids to believe in their <br />
            creative self expression by bringing their <br />
            screations to life through themes and stories <br />
            centered around social emotional learning.
          </Text>
          <Button
            variant="white"
            onClick={onClick}
            display={["none", "none", "inline-flex"]}
          >
            Try our app
          </Button>
        </Box>
        {IS_PROD ? (
          <Box w="200px" maxW={"100%"} />
        ) : (
          <Flex>
            <Button variant="ghostWhite" onClick={() => navigate("/")}>
              Home
            </Button>
            <Button variant="ghostWhite" onClick={() => navigate("/changelog")}>
              Changelog
            </Button>
          </Flex>
        )}
        <Box display={["block", "block", "none"]} textAlign="center">
          <Button variant="white" onClick={onClick}>
            Try our app
          </Button>
          <Text size="xs" color="white" fontWeight="light" my="1rem">
            Our goal is to enable kids to believe in their <br />
            creative self expression by bringing their <br />
            creations to life through themes and stories <br />
            centered around social emotional learning.
          </Text>
        </Box>

        <Flex color="white" gap="1rem">
          <a
            href="https://www.instagram.com/ministudio.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon height="24px" width="24px" />
          </a>
          <a
            href="https://www.facebook.com/ministudioai"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon height="24px" width="24px" />
          </a>
          <a
            href="https://twitter.com/ministudio_AI"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon height="24px" width="24px" />
          </a>
          <a
            href="https://www.linkedin.com/company/mini-studio-ai"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon height="24px" width="24px" />
          </a>
        </Flex>
      </Flex>
    </Box>
  )
}
