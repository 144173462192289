import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import pixel from "../../assets/images/pixel.png"
import comicJellyFish from "../../assets/images/landing/comic-jellyfish.png"
import comicPanda from "../../assets/images/landing/comic-panda.png"
import QuoteIcon from "../../components/Icons/IconQuote"

export default function ComicsBlock({ onClick }: { onClick: () => void }) {
  return (
    <Flex
      py={["2rem", "2rem", "2rem", "2rem", "4rem"]}
      px="1rem"
      gap="1rem"
      mx="auto"
      maxWidth="1300px"
      flexDir={["column", "column", "column", "column", "row"]}
    >
      <Flex flex="1" flexDir="column">
        <Heading
          fontSize={["1.5rem", "1.5rem", "2rem", "2rem", "2.5rem"]}
          color={["black", "black", "black", "black", "secondary.500"]}
          textAlign={["center", "center", "center", "center", "left"]}
        >
          Kids & parents are
          <br /> loving this
        </Heading>
        <Text
          size="sm"
          py="1.5rem"
          w={["100%", "350px", "350px", "350px", "auto"]}
          textAlign={["center", "center", "center", "center", "left"]}
          mx="auto"
        >
          We harness the power of AI to unleash children’s creative potential.
          Kids can transform their drawings to 3D characters, MiniTales,
          stories, animated cartoons, toys, you name it… Through our guided
          personalized stories, parents can pass on the messages they choose for
          their children.
        </Text>
        <Button
          mx="auto"
          display={["none", "none", "none", "none", "inline-flex"]}
          variant="fullSecondary"
        >
          Try our app
        </Button>
        <Image
          src={pixel}
          w="250px"
          mr="auto"
          mt="2rem"
          display={["none", "none", "none", "none", "block"]}
        />
      </Flex>
      <Flex flexDir="column" gap={[0, 0, 0, 0, "1rem"]} mx="auto">
        <Image
          src={comicPanda}
          w={["100%", "100%", "400px", "500px", "360px"]}
          h={["100%", "100%", "400px", "500px", "360px"]}
        />
        <Flex
          bg={[
            "secondary.300",
            "secondary.300",
            "secondary.300",
            "secondary.300",
            "secondary.500",
          ]}
          textAlign="center"
          p="1rem"
          lineHeight="1.75rem"
          w={["100%", "100%", "400px", "500px", "360px"]}
          h={["400px", "400px", "400px", "500px", "360px"]}
          flexDir="column"
        >
          <QuoteIcon color="white" h="70px" w="80px" mx="auto" mt="auto" />
          <Text size="sm" color="white">
            Although it's a kids book, Jelly & The Underwater Kingdom is a work
            of art. Ashton will be truly stoked with his very own MiniTale. He
            made such a big deal showing off the character drawing to his
            parents once he realized it was made from his jellyfish on the
            fridge. It's such a solid product idea with a very cute impact!
          </Text>
          <Text fontWeight="bold" size="sm" color="white" mb="auto">
            Gift from Papa Ted, to Ashton, 4 yrs old
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column",
        ]}
        w={["100%", "100%", "400px", "500px", "360px"]}
        gap={[0, 0, 0, 0, "1rem"]}
        mx="auto"
      >
        <Flex
          bg={[
            "secondary.300",
            "secondary.300",
            "secondary.300",
            "secondary.300",
            "secondary.500",
          ]}
          textAlign="center"
          p="1rem"
          lineHeight="1.75rem"
          w={["100%", "100%", "400px", "500px", "360px"]}
          h={["400px", "400px", "400px", "500px", "360px"]}
          flexDir="column"
        >
          <QuoteIcon color="white" h="70px" w="80px" mx="auto" mt="auto" />
          <Text size="sm" color="white">
            They all look so cute. I was touched by how seriously the kids took
            the decision. They really pondered. Thank you so much! These are
            gorgeous!
          </Text>
          <Text fontWeight="bold" size="sm" color="white" mb="auto">
            CrissiBeth Mom of Olivia, 7
          </Text>
        </Flex>

        <Image
          src={comicJellyFish}
          w={["100%", "100%", "400px", "500px", "360px"]}
          h={["100%", "100%", "400px", "500px", "360px"]}
        />
      </Flex>
      <Button
        mx="auto"
        display={[
          "inline-flex",
          "inline-flex",
          "inline-flex",
          "inline-flex",
          "none",
        ]}
        onClick={onClick}
        variant="fullSecondary"
      >
        Try our app
      </Button>
    </Flex>
  )
}
