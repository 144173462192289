import { Box, BoxProps } from "@chakra-ui/react"
import bg from "../../assets/images/backgrounds/app-bg.png"

export default function BackgroundImageBlurred({
  backgroundImage = bg,
  backgroundColor = "rgba(0,0,0,0.5)",
  bgPos = "50% 65%",
  blur = true,
}: {
  backgroundImage?: BoxProps["backgroundImage"]
  backgroundColor?: BoxProps["backgroundColor"]
  blur?: boolean
  bgPos?: BoxProps["backgroundPosition"]
}) {
  return (
    <>
      <Box
        backgroundImage={backgroundImage}
        bgPos={bgPos}
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100%"
        position="absolute"
        left="0"
        top="0"
        w="100%"
        zIndex={-1}
      />
      <Box
        background={backgroundColor}
        position="fixed"
        top="0"
        left="0"
        height="100%"
        width="100%"
        zIndex={-1}
        transition="1s"
        backdropFilter={
          blur ? "blur(6px)" : ["blur(0)", "blur(0)", "blur(6px)"]
        }
      />
    </>
  )
}
