import { Box, Flex, Grid, Image, Text, useDisclosure } from "@chakra-ui/react"
import onboardingBg1 from "../../assets/images/onboarding/onboarding-1.png"
import onboardingIllustration2 from "../../assets/images/onboarding/onboarding-2-illustration.png"
import onboardingBg2 from "../../assets/images/onboarding/onboarding-2.png"
import onboardingBg3 from "../../assets/images/onboarding/onboarding-3.mp4"
import onboardingGroupPhoto from "../../assets/images/onboarding/onboarding-character-group.png"
import onboardingClouds from "../../assets/images/onboarding/onboarding-clouds.png"
import ActionButton from "../../components/Buttons/ActionButton"
import BackButton from "../../components/Buttons/BackButton"
import Button3d from "../../components/Buttons/Button3d"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"
import ClickIcon from "../../components/Icons/ClickIcon"
import CreatePopup from "../../components/Popup/CreatePopup"
import Background from "../../components/Wrapper/Background"
import BackgroundImageBlurred from "../../components/Wrapper/BackgroundImageBlurred"
import "./Onboarding.css"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../../context/userContext"

export function OnboardingStep1({ onNext }: { onNext: () => void }) {
  return (
    <Background display="flex" flexDir="column">
      <BackgroundImageBlurred
        backgroundImage={onboardingBg1}
        bgPos="center"
        backgroundColor="transparent"
        blur={false}
      />
      <Flex
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)"
        p="1rem"
      >
        <Grid gridTemplateColumns={"1fr"} w="100%" maxW="800px" m="auto">
          <Text
            fontWeight="bold"
            fontSize={["24px", "32px"]}
            color="white"
            textAlign="center"
          >
            Create Characters! Chat & Learn Together
          </Text>
        </Grid>
      </Flex>
      <Button3d
        background="#FBF6F3"
        bg3dColor="#9C9998"
        color="#333333"
        mx="auto"
        mt="auto"
        mb="2rem"
        size="lg"
        onClick={onNext}
      >
        Let's go
      </Button3d>
    </Background>
  )
}

export function OnboardingStep2({ onNext }: { onNext: () => void }) {
  return (
    <Background
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="hidden"
    >
      <BackgroundImageBlurred
        backgroundImage={onboardingBg2}
        bgPos="center"
        backgroundColor="#0000007f"
        blur={false}
      />
      <Flex
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)"
        p="1rem"
      >
        <Grid
          gridTemplateColumns={"50px 1fr 50px"}
          w="100%"
          maxW="800px"
          m="auto"
        >
          <BackButton color="white" />
          <Text
            fontWeight="bold"
            fontSize={["24px", "32px"]}
            color="white"
            textAlign="center"
            placeSelf="center"
          >
            Ask Them Anything!
          </Text>
        </Grid>
      </Flex>
      <Image
        position="fixed"
        src={onboardingIllustration2}
        w={["400px", "400px", "500px"]}
        minW={["420px", "400px", "500px"]}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      <Button3d mx="auto" mb="2rem" size="lg" onClick={onNext}>
        <ArrowRightIcon h="50px" w="100px" />
      </Button3d>
    </Background>
  )
}

export function OnboardingStep3({ onNext }: { onNext: () => void }) {
  return (
    <Background
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      overflow="hidden"
    >
      <video
        src={onboardingBg3}
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: -1,
        }}
        autoPlay
        muted
        loop
        disablePictureInPicture
      ></video>
      <Flex
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)"
        p="1rem"
      >
        <Grid
          gridTemplateColumns={"50px 1fr 50px"}
          w="100%"
          maxW="800px"
          m="auto"
        >
          <BackButton color="white" placeSelf="center" />
          <Text
            fontWeight="bold"
            fontSize={["24px", "32px"]}
            color="white"
            textAlign="center"
            placeSelf="center"
          >
            Go on Epic Adventures Together
          </Text>
        </Grid>
      </Flex>
      <Button3d
        background="#FBF6F3"
        bg3dColor="#9C9998"
        color="#333333"
        mx="auto"
        mt="auto"
        mb="2rem"
        size="lg"
        onClick={onNext}
      >
        Start the Adventure
      </Button3d>
    </Background>
  )
}

export function OnboardingStep4({ onNext }: { onNext: () => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { setOnboardingDone } = useUserContext()

  const validateOnboardingAndRedirect = (redirectTo: string) => {
    return () => setOnboardingDone().then(() => navigate(redirectTo))
  }

  return (
    <Background display="flex" flexDir="column" overflow="auto">
      <CreatePopup
        isOpen={isOpen}
        onClose={onClose}
        onClickDescribe={validateOnboardingAndRedirect("/upload?describe=true")}
        onClickDraw={validateOnboardingAndRedirect("/draw")}
        onClickUpload={validateOnboardingAndRedirect("/upload")}
      />
      <BackgroundImageBlurred
        blur
        backgroundImage={onboardingClouds}
        backgroundColor="transparent"
        bgPos="center"
      />
      <Flex
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)"
        p={["0.5rem", "1rem"]}
      >
        <Grid
          gridTemplateColumns={"30px 1fr 30px"}
          w="100%"
          maxW="800px"
          m="auto"
        >
          <BackButton color="white" placeSelf="center" />
          <Text
            alignSelf="center"
            fontWeight="bold"
            fontSize={["24px", "32px"]}
            color="white"
            textAlign="center"
            placeSelf="center"
          >
            Let's begin
          </Text>
        </Grid>
      </Flex>
      <Flex flexDir="column" mx="auto" mt="auto">
        <Text
          p="1rem"
          bg="white"
          height="fit-content"
          borderRadius="12px"
          w="fit-content"
          mx="auto"
          boxShadow="0px 4px 6px  rgba(0, 0, 0, 0.21)"
          mb="1rem"
          fontSize={["16px", "20px"]}
        >
          Create your first Mini
        </Text>
        <Image
          src={onboardingGroupPhoto}
          maxW="100%"
          maxH="40vh"
          objectFit="contain"
          w="600px"
          filter="drop-shadow(0 0 4px #0000007f)"
        />
      </Flex>
      <Flex mx="auto" mt={["16px", "32px", "48px"]} mb="auto" flexDir="column">
        <Text fontWeight="bold" w="fit-content" textAlign="center" mb="1.5rem">
          Tap here
        </Text>
        <Box position="relative" className="animated-create-button" mx="auto">
          <ActionButton
            buttonType="create"
            iconSize={["48px", "48px", "64px"]}
            onClick={onOpen}
          />
          <ClickIcon
            position="absolute"
            width="75px"
            height="75px"
            bottom={["-40px", "-40px", "-30px"]}
            right={["-40px", "-40px", "-30px"]}
            pointerEvents="none"
          />
        </Box>
      </Flex>
    </Background>
  )
}
