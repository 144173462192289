import { Icon } from "@chakra-ui/react"

const QuoteIcon: typeof Icon = (props) => (
  <Icon viewBox="0 0 53 53" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0266 16.9055C39.359 16.2406 38.8755 15.4583 38.1321 15.4583H33.1252L28.7085 24.2916V36.5416C28.7085 37.0939 29.1562 37.5416 29.7085 37.5416H40.9585C41.5108 37.5416 41.9585 37.0939 41.9585 36.5416V25.2916C41.9585 24.7393 41.5108 24.2916 40.9585 24.2916H35.3335L39.0266 16.9055ZM21.3599 16.9055C21.6923 16.2406 21.2088 15.4583 20.4655 15.4583H15.4585L11.0418 24.2916V36.5416C11.0418 37.0939 11.4895 37.5416 12.0418 37.5416L23.2918 37.5416C23.8441 37.5416 24.2918 37.0939 24.2918 36.5416V25.2916C24.2918 24.7393 23.8441 24.2916 23.2918 24.2916L17.6668 24.2916L21.3599 16.9055Z"
      fill="black"
      fillOpacity="0.7"
    />
    <mask
      id="mask0_1816_1116"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="15"
      width="31"
      height="23"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0266 16.9055C39.359 16.2406 38.8755 15.4583 38.1321 15.4583H33.1252L28.7085 24.2916V36.5416C28.7085 37.0939 29.1562 37.5416 29.7085 37.5416H40.9585C41.5108 37.5416 41.9585 37.0939 41.9585 36.5416V25.2916C41.9585 24.7393 41.5108 24.2916 40.9585 24.2916H35.3335L39.0266 16.9055ZM21.3599 16.9055C21.6923 16.2406 21.2088 15.4583 20.4655 15.4583H15.4585L11.0418 24.2916V36.5416C11.0418 37.0939 11.4895 37.5416 12.0418 37.5416L23.2918 37.5416C23.8441 37.5416 24.2918 37.0939 24.2918 36.5416V25.2916C24.2918 24.7393 23.8441 24.2916 23.2918 24.2916L17.6668 24.2916L21.3599 16.9055Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_1816_1116)">
      <rect width="53" height="53" fill="currentColor" />
    </g>
  </Icon>
)

export default QuoteIcon
