import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { extractColorsFromSrc } from "extract-colors"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Creation from "../../../data/models/Creation"
import CreationService from "../../../service/CreationService"
import { getAgeString } from "../../../utilities/dateUtils"
import whiteCloud from "../../assets/images/white-cloud.png"
import ClickIcon from "../../components/Icons/ClickIcon"

async function extractColor(
  image: string,
  backupColor: string
): Promise<string> {
  try {
    return await extractColorsFromSrc(image, {
      crossOrigin: "anonymous",
      splitPower: 2,
    }).then((colors) => {
      let color = colors
        .filter(
          (color) =>
            color.lightness < 0.8 &&
            color.lightness > 0.4 &&
            color.saturation > 0.2
        )
        .sort(
          (a, b) =>
            b.saturation +
            b.lightness +
            b.area -
            (a.saturation + a.lightness + a.area)
        )[0]
      if (color) return color.hex
      return backupColor
    })
  } catch (e) {
    return backupColor
  }
}

export default function CharacterWithCloud({
  creation,
}: {
  creation: Creation
}) {
  const navigate = useNavigate()
  const { picture, subText, isUnopened } = useMemo(() => {
    if ((creation.selectedImage?.length ?? 0) > 0)
      return {
        picture: CreationService.getCroppedPicture(creation),
        subText: getAgeString(creation.createdAt),
        isUnopened: false,
      }
    return {
      picture: creation?.generativeAiResultsCropped?.[0],
      subText: "Ready!",
      isUnopened: true,
    }
  }, [creation])
  const [color, setColor] = useState("#777")

  useEffect(() => {
    if (!isUnopened || !picture) return

    extractColor(picture, "#777").then((color) => setColor(color))
  }, [picture, isUnopened])

  return (
    <Flex
      position="relative"
      pt={["80px", "80px", "150px"]}
      gridColumn="2"
      flexDir="column"
      onClick={async () => {
        if (isUnopened) {
          if (creation.generativeAiResults?.length === 4)
            await CreationService.setGiftOpen(creation)
          navigate(`/creations/${creation.id}`)
        } else {
          navigate(`/chat/${creation.id}`)
        }
      }}
      cursor={"pointer"}
    >
      <Image
        src={whiteCloud}
        w={["200px", "250px", "300px", "400px"]}
        height="auto"
        objectFit="contain"
        position="absolute"
        bottom={["20px", "20px", "10px", "0px"]}
        left="50%"
        transform="translateX(-50%)"
        zIndex={0}
      />
      {isUnopened ? (
        <>
          <Box
            style={{
              WebkitMaskBoxImage: `url(${picture})`,
              WebkitMaskBoxImageRepeat: "no-repeat",
              WebkitMaskPosition: "center",
              WebkitMaskSize: "contain",
            }}
            bg={color}
            position="relative"
          >
            {/* Image added here for adjusting parent's width */}
            <Image
              src={picture}
              minW="fit-content"
              minH={["200px", "250px", "300px", "400px"]}
              maxH={["200px", "250px", "300px", "400px"]}
              opacity={0}
            />
          </Box>
          <Flex
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            flexDir="column"
            textAlign="center"
            w="100%"
          >
            <ClickIcon
              color="white"
              height="75px"
              w="auto"
              filter="drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.9))"
            />
            <Text
              w="100%"
              color="white"
              textShadow={"0px 0px 20px rgba(0, 0, 0, 0.9)"}
            >
              Open now!
            </Text>
          </Flex>
        </>
      ) : (
        <Image
          src={picture}
          width={["200px", "250px", "300px", "400px"]}
          maxH={["200px", "250px", "300px", "400px"]}
          height="auto"
          objectFit="contain"
          zIndex={1}
        />
      )}
      <Text
        color="white"
        fontWeight="bold"
        fontSize={["30px", "32px", "36px", "38px"]}
        textShadow={"0px 0px 10px rgba(0, 0, 0, 0.9)"}
        w="90%"
        textAlign="center"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        mx="auto"
        zIndex={1}
      >
        {creation?.title}
      </Text>
      <Text
        color="white"
        fontWeight="normal"
        fontSize="18px"
        textShadow={"0px 0px 10px rgba(0, 0, 0, 0.9)"}
        w="100%"
        textAlign="center"
        zIndex={1}
        mb="40px"
>
        {subText}
      </Text>
    </Flex>
  )
}
