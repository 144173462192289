import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import circle from "../../assets/images/landing/circle.png"
import dinoVideo from "../../assets/images/onboarding/dino-video.mp4"
import underwaterVideo from "../../assets/images/onboarding/shark-video.mp4"
import dinoComic from "../../assets/images/onboarding/dino-comic.png"
import "./StoriesBlock.css"

export default function StoriesBlock({ onClick }: { onClick: () => void }) {
  return (
    <Flex
      textAlign="center"
      flexDir={["column", "column", "column", "column", "row"]}
      overflow="hidden"
      mt={[0, 0, 0, 0, "-150px"]}
      maxW="1600px"
      m="auto"
    >
      <Box
        position="relative"
        ml={["-100px", "-100px", "-100px", "auto"]}
        m={["", "", "", "auto"]}
        maxW={["450px", "600px", "700px"]}
      >
        <Image
          src={circle}
          borderRadius={"50%"}
          w={["450px", "600px", "700px"]}
          h={["450px", "600px", "700px"]}
          objectFit={"cover"}
        />
        <video
          playsInline
          src={underwaterVideo}
          className="video-story video-shark"
          autoPlay
          muted
          loop
        />
        <Image
          src={dinoComic}
          position="absolute"
          top={["5%", "10%"]}
          w={["200px", "200px", "250px"]}
          left={["50%", "60%"]}
          transform="rotate(15deg)"
        />
        <video
          playsInline
          src={dinoVideo}
          className="video-story video-dino"
          autoPlay
          muted
          loop
        />
      </Box>
      <Box m="auto" py="4rem" px="1rem">
        <Heading size={["sm", "sm", "sm", "md"]}>
          Make animated stories <br />& books with your heroes
        </Heading>
        <Text size="sm" my="1.5rem" maxW={"420px"}>
          Parents choose the theme of the stories from our carefully designed
          narratives for social emotional growth
        </Text>
        <Button variant="fullSecondary" onClick={onClick}>
          Try our app!
        </Button>
      </Box>
    </Flex>
  )
}
