import { Box, Textarea } from "@chakra-ui/react"
import axios from "axios"
import { useState } from "react"
import cleanPrompt from "../../../../utilities/cleanPrompt"
import CamcamGuess, { GuessState } from "./CamcamGuess"

// TODO use generic speech to text button
export default function ImageDescription({
  description,
  setDescription,
  imageUrl,
}: {
  description: string
  setDescription: (description: string) => void
  imageUrl?: string
}) {
  const [guessing, setGuessing] = useState("none" as GuessState)
  // const { interimResult, isRecording, startSpeechToText, stopSpeechToText } =
  //   useSpeechToText({
  //     googleApiKey: GOOGLE_SPEECH_TO_TEXT_CLOUD_API_KEY,
  //   })

  // useEffect(() => {
  //   if (interimResult) setDescription(interimResult)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [interimResult])

  // const startListening = () => {
  //   startSpeechToText()
  // }
  // const stopListening = () => {
  //   stopSpeechToText()
  // }

  const fetchImageDescription = (img: string) => {
    setGuessing("guessing")
    axios
      .post("https://api.ministudio.ai/img2text-b64", {
        image_b64: img.split(",")[1],
      })
      .then((res) => {
        setDescription(cleanPrompt(res.data.data))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setGuessing("guessed")
      })
  }

  return (
    <>
      <Box position="relative" w="100%">
        {imageUrl && (
          <CamcamGuess
            guess={guessing}
            onGuess={() => fetchImageDescription(imageUrl)}
          />
        )}
        <Textarea
          bg="white"
          placeholder={
            // isRecording ? "Listening..." :
            "Describe your mini character..."
          }
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          borderRadius="12px"
          border="2px solid"
          borderColor="#494949"
          variant="unstyled"
          fontWeight="500"
          p="1rem 1.5rem"
        />
      </Box>
    </>
  )
}
